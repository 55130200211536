<template>
    <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/contentConfig/rotationManage"
          >轮播图管理 / </router-link>
          <span class="crumbs_item crumbs_last">{{operation==1?'新建':'编辑'}}{{type==1?'App首页':type==2?'App精选':type==3?'Pc首页':'综合商城'}}轮播图</span></span>
      </div>
    </div>
    <div class="all_content_box">
      <div class="Up_Down_inner">
        <span class="all_left_name all_required">主图</span>
        <div>
          <a-upload
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
            list-type="picture-card"
            :showUploadList="false"
            :beforeUpload="beforeUpload"
            :customRequest="customRequest"
          >
            <img v-if="form.img" :src="form.img" style="max-width:180px" alt="avatar" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
        </div>
      </div>
      <div class="Up_Down_inner">
        <div class="all_left_name">标题</div>
        <div>
          <a-input
            v-model="form.title"
            class="all_input all_margin-r"
            placeholder="请输入标题"
          />
        </div>
      </div>
      <div class="Up_Down_inner" >
        <div class="all_left_name ">链接</div>
        <div>
          <a-input
            v-model="form.link"
            class="all_input all_margin-r"
            placeholder="请输入需要跳转的链接"
          />
        </div>
      </div>
      <div v-if="this.type == 2" class="Up_Down_inner">
        <div class="all_left_name">背景色值</div>
        <div>
          <a-input
            :maxLength="7"
            v-model="form.bgColorFirst"
            class="all_input all_margin-r short"
            placeholder="请输入色值"
          />
          <a-input
            :maxLength="7"
            v-model="form.bgColorSecond"
            class="all_input all_margin-r short"
            placeholder="请输入色值"
          />
          <span class="greytext">输入十六进制的色值例如“#ffffff”</span>
        </div>
      </div>
    </div>
    <div class="button">
      <a-button
        type="primary"
        class="btn"
        @click="addForm"
        :loading="loading"
      >确认</a-button>
      <a-button class="all_boder_btn btn" @click="goBack">取消</a-button>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {}, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      type:'',
      operation:'',
      loading:false,
      form: {
        rotationId:'',
        img:'',  // 图片
        link:'',  // 跳转链接
        title:'', // 标题
        type:'',  // 所属模块 1.app首页，2.app精选 3pc首页 4综合商城
        bgColorFirst:'', //渐变色
        bgColorSecond:'',
      },
    };
  }, // 事件处理器
  methods: {
    // 上传前钩子上传图片
    beforeUpload(file){
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg)
        return false
      }
    },

    // 文件上传
    customRequest(fileData) {
      this.loading = true;

      const bucket = 'ueditor/banner/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.form.img = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    // 增加数据
    addForm(){
      // 校验图片 
      if(!this.form.img){
        this.$message.warning('请上传图片');
        return
      }
      
      // 校验链接
      // if(this.form.link){
      //   if(!this.$regular.link.reg.test(this.form.link)){
      //     this.$message.warning(this.$regular.link.msg);
      //     return
      //   }
      // }
      // 校验颜色值
      if(this.form.bgColorFirst){
        if(!this.$regular.color.reg.test(this.form.bgColorFirst)){
          this.$message.warning(this.$regular.color.msg);
          return
        }
      }
      // 校验颜色值
      if(this.form.bgColorSecond){
        if(!this.$regular.color.reg.test(this.form.bgColorSecond)){
          this.$message.warning(this.$regular.color.msg);
          return
        }
      }
      // 颜色值--
      if((this.form.bgColorFirst && !this.form.bgColorSecond) || (!this.form.bgColorFirst && this.form.bgColorSecond)){
        this.$message.warning('请填写背景颜色值');
        return
      }
      this.form.type = this.type
      this.loading = true
      this.$ajax({
        method: this.form.rotationId ? 'put' : 'post',
        url: '/hxclass-management/rotation/',
        params: this.form
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.$router.push("/admin/contentConfig/rotationManage?type=" + this.type);
        }
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.warning('系统错误');
        }
      })
    },
    getFormDetai(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/rotation/details/" + this.form.rotationId,
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.form = res.data
          if(this.type != 2) {  // 非精选，制空两个色值
            this.form.bgColorFirst = undefined
            this.form.bgColorSecond = undefined
          }
        }
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.warning('系统错误');
        }
      })
    },
    goBack(){
      this.$router.push("/admin/contentConfig/rotationManage?type=" + this.type);
    }
  }, // 生命周期-实例创建完成后调用
  created() {
    this.type = this.$route.query.type;
    this.form.rotationId = this.$route.query.id;
    if(this.form.rotationId){
      this.getFormDetai()
    }
    this.operation = this.$route.query.operation;
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  a {
    color: #333333;
  }
  .greytext {
       font-size: 14px;
       color: #888;
  }
  .all_content_box {
    .Up_Down_inner {
      display: block;
      margin-top: 24px;
      textarea.ant-input,
      .all_input {
        width: 525px;
      }
      textarea.ant-input {
        height: 192px;
      }
      .ant-calendar-picker,
      .input {
        width: 397px;
      }
      .short{
        width: 197px;
      }
    }
  }

  .button {
    .btn {
      margin: 36px 14px;
    }
  }
}
</style>
